






































































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import IBusinessAccount from '@/interfaces/models/business-account.interface';
import {Helpers} from "@/common/utils/helpers.js";
import {Getter} from "vuex-class";
import {IStripeProduct} from "@/interfaces/models/IStripeProduct";
import {IStripePrice} from "@/interfaces/models/IStripePrice";
import * as actionTypes from "@/store/action-types";
import ConfirmModal from '@/components/admin-settings/ConfirmModal.vue';
import { ConfirmModalButtonColorEnum, IConfirmModal } from '@/interfaces/confirm-modal.interface';
import { DEFAULT_CONFIRM_MODAL_CONFIG } from '@/common/constants';
import { NotificationType } from '@/interfaces/models/INotification';
import IAgentAccount, {AgentRole} from "@/interfaces/models/agent-account.interface";

@Component({
  components: {
    ConfirmModal
  }
})
export default class TableRow extends Vue {
  @Getter stripePrices!: IStripePrice[];
  @Getter stripeProducts!: IStripeProduct[];
  @Getter currentBusinessAccount!: IBusinessAccount;
  @Getter agent!: IAgentAccount;
  @Prop({type: Array, required: true}) businessAccounts!: IBusinessAccount[];
  showConfirmModal: boolean = false;
  confirmModalConfig: IConfirmModal = DEFAULT_CONFIRM_MODAL_CONFIG;
  confirmLoading: boolean = false;

  @Emit('edit')
  edit(businessAccount: IBusinessAccount): IBusinessAccount {
    return businessAccount;
  }

  async loginAsAdmin(businessAccount: IBusinessAccount): Promise<void> {
    if (businessAccount.id === this.currentBusinessAccount.id) {
        this.confirmModalConfig = {
        title        : 'Login as administrator',
        label        : `You are already in ${businessAccount.name} account !`,
        buttonText   : 'Ok',
        buttonColor  : ConfirmModalButtonColorEnum.CONFIRM,
        confirmAction: async () => {
          try {
            this.showConfirmModal = false;
          } catch (e) {
            await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
              text: e.message,
              type: NotificationType.ERROR
            });
          } finally {
            this.showConfirmModal = false;
            this.confirmLoading = false;
          }
        },
      };
      this.showConfirmModal = true;
    } else {  this.confirmModalConfig = {
        title        : 'Login as administrator',
        label        : `Are you sure you want to login in ${businessAccount.name} account ?`,
        buttonText   : 'Login',
        buttonColor  : ConfirmModalButtonColorEnum.CONFIRM,
        confirmAction: async () => {
          this.confirmLoading = true;
          try {
            await this.$store.dispatch(actionTypes.LOGIN_AS_ADMIN, {
              originalBusinessAccountId: this.currentBusinessAccount.id,
              targetBusinessAccountId: businessAccount.id
              });
            this.showConfirmModal = false;
            await this.handleNextPageNavigation();
          } catch (e) {
            await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
              text: e.message,
              type: NotificationType.ERROR
            });
          } finally {
            this.showConfirmModal = false;
            this.confirmLoading = false;
          }
        },
      };
      this.showConfirmModal = true;
    }
  }

  async handleNextPageNavigation(): Promise<void> {
    if (this.currentBusinessAccount.isExpired && this.agent.role === AgentRole.ADMINISTRATOR) {
      await this.$router.push('/settings/billing?tab=change-plan');
      if (this.currentBusinessAccount.expiredMessage) {
        await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
          text: this.currentBusinessAccount.expiredMessage,
          type: NotificationType.ERROR
        });
      }
    } else if (this.agent.businessAccount.isExpired && this.agent.role !== AgentRole.ADMINISTRATOR) {
      await this.$store.dispatch(actionTypes.SIGN_OUT);
      this.$store.dispatch(actionTypes.RESET);
      await this.$router.push('/plan-expire');
    } else {
      await this.$router.push('/inbox');
      this.$router.go(0);
    }
  }

  async mounted(): Promise<void> {
    await this.$store.dispatch(actionTypes.FETCH_STRIPE_PRODUCTS);
    await this.$store.dispatch(actionTypes.FETCH_STRIPE_PRICES, null);
  }

  getFormattedNumber(phoneNumber: string): string {
    return Helpers.formatNumber(phoneNumber);
  }

  getPlanDetail(businessAccount: IBusinessAccount): string {
    return Helpers.getPlanDetails(businessAccount, this.stripeProducts, this.stripePrices);
  }

  async toggleBusinessAccountStatus(businessAccount: IBusinessAccount): Promise<void> {
    if (businessAccount.enabled) {
      this.confirmModalConfig = {
        title        : 'Disable business account',
        label        : `Are you sure you want to disable company ${businessAccount.name} ?`,
        buttonText   : 'Disable',
        buttonColor  : ConfirmModalButtonColorEnum.CONFIRM,
        confirmAction: async () => {
          try {
            this.confirmLoading = true;
            await this.$store.dispatch(actionTypes.DISABLE_BUSINESS_ACCOUNT, businessAccount.id);
          } catch (e) {
            await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
              text: e.message,
              type: NotificationType.ERROR
            });
          } finally {
            this.showConfirmModal = false;
            this.confirmLoading = false;
          }
        },
      };
      this.showConfirmModal = true;
    } else {
      this.confirmModalConfig = {
        title        : 'Enable business account',
        label        : `Are you sure you want to enable company ${businessAccount.name}?`,
        buttonText   : 'Enable',
        buttonColor  : ConfirmModalButtonColorEnum.CONFIRM,
        confirmAction: async () => {
          try {
            this.confirmLoading = true;
            await this.$store.dispatch(actionTypes.ENABLE_BUSINESS_ACCOUNT, businessAccount.id);
          } catch (e) {
            await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
              text: e.message,
              type: NotificationType.ERROR
            });
          } finally {
            this.showConfirmModal = false;
            this.confirmLoading = false;
          }
        },
      };
      this.showConfirmModal = true;
    }
  }
}
