


















































import {Component, Vue} from 'vue-property-decorator';
import BusinessAccountsTable from "@/components/business-accounts/BusinessAccountsTable.vue";
import {Getter} from "vuex-class";
import * as actionTypes from "@/store/action-types";
import IBusinessAccount from '@/interfaces/models/business-account.interface';
import NavigationDrawer from "@/components/header/NavigationDrawer.vue";
import {SUPER_ADMIN_MENU_ITEMS} from "@/common/utils/admin-menu-items";
import SettingItemInterface from '@/interfaces/setting-item.interface';
import SettingItem from "@/components/admin-settings/SettingItem.vue";

@Component({
  components: {
    NavigationDrawer,
    BusinessAccountsTable,
    SettingItem
  }
})
export default class SuperAdmin extends Vue {
  @Getter businessAccounts!: IBusinessAccount[];

  get menuItems(): SettingItemInterface[] {
    return SUPER_ADMIN_MENU_ITEMS;
  }

  async mounted(): Promise<void> {
    await this.$store.dispatch(actionTypes.FETCH_BUSINESS_ACCOUNTS);
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xs;
  }
}
