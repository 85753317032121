






















































































































































import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator';
import {Validate} from "vuelidate-property-decorators";
import {required} from "vuelidate/lib/validators";
import * as actionTypes from "@/store/action-types";
import {NotificationType} from "@/interfaces/models/INotification";
import IBusinessAccount from '@/interfaces/models/business-account.interface';
import {Helpers} from "@/common/utils/helpers.js";

@Component({
  components: {}
})
export default class EditReplyPopup extends Vue {
  @Prop({type: Boolean, required: true}) showDialog!: boolean;
  @Prop({type: Object, default: null}) businessAccount!: IBusinessAccount | null;

  @Validate({required}) 
  companyName: string = '';
  @Validate({required}) 
  email: string = '';
  @Validate({required}) 
  phone: string = '';
  @Validate({required}) 
  proxyNumber: string = '';

  callForwardingNumber: string = '';

  isLoading: boolean = false;

  @Watch('businessAccount')
  observeBusinessAccount(): void {
    this.companyName = this.businessAccount?.name || '';
    this.email = this.businessAccount?.email || '';
    this.phone = this.businessAccount?.phone ? this.businessAccount?.phone.slice(2)
      .replace(/(\d\d\d)(\d\d\d)(\d\d\d\d)/, '($1) $2-$3') : '';
    this.proxyNumber = this.businessAccount?.messageProxyNumber ? this.businessAccount?.messageProxyNumber.slice(2)
      .replace(/(\d\d\d)(\d\d\d)(\d\d\d\d)/, '($1) $2-$3') : '';
    this.callForwardingNumber = this.businessAccount?.callForwardingNumber ? this.businessAccount?.callForwardingNumber.slice(2)
      .replace(/(\d\d\d)(\d\d\d)(\d\d\d\d)/, '($1) $2-$3') : '';
  }

  @Emit('close-popup')
  closePopup(): void {
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xs;
  }

  @Watch('showDialog')
  showDialogWatcher(): void {
    if (this.showDialog) {
      document.addEventListener('keyup', this.handleCloseDialog);
    } else {
      document.removeEventListener('keyup', this.handleCloseDialog);
    }
  }

  handleCloseDialog(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.closePopup();
    }
  }

  async saveBusinessAccount(): Promise<void> {
    this.$v.$touch();

    if (this.$v.$invalid) {
      return;
    }

    try {
      this.isLoading = true;
      const actionType = actionTypes.UPDATE_BUSINESS_ACCOUNT;
      await this.$store.dispatch(actionType, {
        id: this.businessAccount!.id,
        name: this.companyName,
        email: this.email,
        phoneNumber: Helpers.formatNumberForApi(this.phone),
        messageProxyNumber: Helpers.formatNumberForApi(this.proxyNumber),
        callForwardingNumber: this.callForwardingNumber ? Helpers.formatNumberForApi(this.callForwardingNumber) : null
      });
      this.closePopup();
      this.$v.$reset();
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    } finally {
      this.isLoading = false;
    }
  }
}
