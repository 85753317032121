














import { Component, Vue, Watch } from 'vue-property-decorator';
import * as actionTypes from '@/store/action-types';
import { Getter } from 'vuex-class';
import BusinessAccountsTable from "@/components/business-accounts/BusinessAccountsTable.vue";
import IBusinessAccount from '@/interfaces/models/business-account.interface';
import EditBusinessAccountPopup from "@/components/business-accounts/EditBusinessAccountPopup.vue";

@Component({
  name: 'Companies',
  components: {
    BusinessAccountsTable,
    EditBusinessAccountPopup
  }
})
export default class Automations extends Vue {
  @Getter businessAccounts!: IBusinessAccount[];
  showDialog: boolean = false;
  businessAccountToEdit: IBusinessAccount | null = null;

  async mounted(): Promise<void> {
    await this.$store.dispatch(actionTypes.FETCH_BUSINESS_ACCOUNTS);
  }

  editBusinessAccount(businessAccount: IBusinessAccount): void {
    this.businessAccountToEdit = businessAccount;
    this.showDialog = true;
  }
}
